/* eslint-disable no-shadow */
import React from 'react';

import { Container, Prev, Next, ToTop } from './styles/portfolionav';

export default function PortfolioNav({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

PortfolioNav.Prev = function PortfolioNavPrev({ children, ...restProps }) {
  return <Prev {...restProps}>{children}</Prev>;
};

PortfolioNav.ToTop = function PortfolioNavToTop({ children, ...restProps }) {
  return <ToTop {...restProps}>{children}</ToTop>;
};

PortfolioNav.Next = function PortfolioNavNext({ children, ...restProps }) {
  return <Next {...restProps}>{children}</Next>;
};
