import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 100px;
  padding: 50px;
  box-sizing: border-box;
`;
