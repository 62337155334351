/* eslint-disable no-shadow */
import React from 'react';
import { Link as ReactRouterLink, useHistory } from 'react-router-dom';
import {
  Container,
  Logo,
  Menu,
  MenuMobileButton,
  MenuItem,
  SubMenu,
  SubMenuItem,
} from './styles/header';

export default function Header({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Header.Logo = function HeaderLogo({ to, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <Logo {...restProps} />
    </ReactRouterLink>
  );
};

Header.Menu = function HeaderMenu({ children, ...restProps }) {
  return <Menu {...restProps}>{children}</Menu>;
};

Header.MenuMobileButton = function HeaderMenuMobileButton({
  children,
  ...restProps
}) {
  return <MenuMobileButton {...restProps}>{children}</MenuMobileButton>;
};

Header.MenuItem = function HeaderMenuItem({
  classNames,
  children,
  ...restProps
}) {
  const history = useHistory();
  const to = Object.values({ ...restProps.to }).join('');
  return (
    <MenuItem
      className={
        history.location.pathname === to ? `${classNames} active` : classNames
      }
      {...restProps}
    >
      {children}
    </MenuItem>
  );
};

Header.SubMenu = function HeaderSubMenu({
  classNames,
  title,
  children,
  ...restProps
}) {
  const history = useHistory();
  const to = title.toLowerCase();

  return (
    <SubMenu
      className={
        history.location.pathname.includes(to)
          ? `${classNames} active`
          : classNames
      }
      {...restProps}
    >
      {title}
      {children}
    </SubMenu>
  );
};

Header.SubMenuItem = function HeaderSubMenuItem({ children, ...restProps }) {
  const history = useHistory();
  const to = Object.values({ ...restProps.to }).join('');

  return (
    <SubMenuItem
      className={history.location.pathname === to ? 'active' : null}
      {...restProps}
    >
      {children}
    </SubMenuItem>
  );
};
