/* eslint-disable no-shadow */
import React from 'react';
import {
  Form,
  Block,
  Label,
  Input,
  TextArea,
  Button,
} from './styles/contactform';

export default function ContactForm({ children, ...restProps }) {
  return <Form {...restProps}>{children}</Form>;
}

ContactForm.Block = function ContactFormBlock({ children, ...restProps }) {
  return <Block {...restProps}>{children}</Block>;
};

ContactForm.Label = function ContactFormLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

ContactForm.Input = function ContactFormInput({ children, ...restProps }) {
  return <Input {...restProps}>{children}</Input>;
};

ContactForm.TextArea = function ContactFormTextArea({
  children,
  ...restProps
}) {
  return <TextArea {...restProps}>{children}</TextArea>;
};

ContactForm.Button = function ContactFormButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};
