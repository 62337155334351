import React from 'react';
import { Footer } from '../components';
import socialData from '../data/sociallinks.json';

export function FooterContainer() {
  return (
    <Footer>
      {socialData.map((social) => (
        <Footer.Link key={social.id} href={social.link}>
          {social.title}
        </Footer.Link>
      ))}
    </Footer>
  );
}
