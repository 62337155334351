/* eslint-disable no-shadow */
import React from 'react';
import { Container, Slide } from './styles/slides';

export default function Slides({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Slides.Slide = function SlidesSlide({ children, ...restProps }) {
  return <Slide {...restProps}>{children}</Slide>;
};
