import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Home, About, Contact, Portfolio, Gallery, ForClients } from './pages';
import * as ROUTES from './constants/routes';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={ROUTES.HOME} />
        </Route>

        <Route path={ROUTES.HOME}>
          <Home />
        </Route>
        <Route path={ROUTES.ABOUT}>
          <About />
        </Route>
        <Route path={ROUTES.CONTACT}>
          <Contact />
        </Route>
        <Route path={ROUTES.FORCLIENTS}>
          <ForClients />
        </Route>

        <Route exact path={ROUTES.ENGAGEMENT}>
          <Portfolio page="Engagement" />
        </Route>
        <Route exact path={ROUTES.WEDDING}>
          <Portfolio page="Wedding" />
        </Route>
        <Route exact path={ROUTES.PORTRAIT}>
          <Portfolio page="Portrait" />
        </Route>
        <Route exact path={ROUTES.FAMILYANDPREGNANCY}>
          <Portfolio page="FamilyAndPregnancy" />
        </Route>

        <Route exact path={`${ROUTES.ENGAGEMENT}/:id`}>
          <Gallery page="Engagement" />
        </Route>
        <Route exact path={`${ROUTES.WEDDING}/:id`}>
          <Gallery page="Wedding" />
        </Route>
        <Route exact path={`${ROUTES.PORTRAIT}/:id`}>
          <Gallery page="Portrait" />
        </Route>
        <Route exact path={`${ROUTES.FAMILYANDPREGNANCY}/:id`}>
          <Gallery page="FamilyAndPregnancy" />
        </Route>
      </Switch>
    </Router>
  );
}
