import React from 'react';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { ContactContainer } from '../containers/contact';

export default function Browse() {
  return (
    <>
      <HeaderContainer />
      <ContactContainer />
      <FooterContainer />
    </>
  );
}
