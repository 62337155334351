/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ForClients, About, ContactForm } from '../components';
import { firebase } from '../lib/firebase.prod';

export function ForClientsContainer() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const clientName = params.get('clientName');

  const [name, setName] = useState('');
  const [clients, setClients] = useState();

  function clickHandler(clientName = name) {
    const client = clients.find((item) => item.name === clientName);

    clientName.length > 3 && clients
      ? client
        ? (window.location.href = client.redirectUrl)
        : alert('Name not found!')
      : null;
  }

  useEffect(() => {
    firebase
      .firestore()
      .collection('clients')
      .get()
      .then((snapshot) => {
        const allContent = snapshot.docs.map((contentObj) => ({
          ...contentObj.data(),
          docId: contentObj.id,
        }));

        setClients(allContent);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  useEffect(() => {
    if (clientName && clients) {
      clickHandler(clientName);
    }
  }, [clients]);

  return (
    <ForClients>
      <About.Title>Get your photos</About.Title>
      <About.Text>Use this form to get your photos</About.Text>
      <ContactForm.Block>
        <ContactForm.Label htmlFor="name">Your Name</ContactForm.Label>
        <ContactForm.Input
          type="text"
          id="name"
          name="name"
          placeHolder="Your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={!clients}
        />
      </ContactForm.Block>
      <ContactForm.Button
        type="submit"
        onClick={() => clickHandler()}
        className={name ? 'active' : 'inactive'}
      >
        Get Photos
      </ContactForm.Button>
    </ForClients>
  );
}
