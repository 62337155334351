import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';

export const Container = styled.div`
  margin-top: 20px;
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(3, 33% [col-start]);
  margin: 30px 0;
  padding: 7.5px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0;
`;

export const Prev = styled(ReactRouterLink)`
  user-select: none;
  text-overflow: ellipsis;
  font-family: Comfortaa;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8em;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  color: #111;
  cursor: pointer;
  justify-self: start;
  align-self: center;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    color: rgba(0, 0, 0, 1);
    transition: 0.5s;
  }

  @media (max-width: 500px) {
    & > span {
      display: none;
    }

    & > svg {
      width: 20px;
    }
  }
`;

export const Next = styled(Prev)`
  justify-self: end;
`;

export const ToTop = styled.p`
  user-select: none;
  text-overflow: ellipsis;
  font-family: Comfortaa;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8em;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  color: #111;
  cursor: pointer;
  justify-self: center;
  align-self: center;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    color: rgba(0, 0, 0, 1);
    transition: 0.5s;
  }
`;
