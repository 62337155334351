import React from 'react';
import { About } from '../components';

export function AboutContainer() {
  return (
    <About>
      <About.Block>
        <About.Image src="/images/about/about.jpeg" />
      </About.Block>
      <About.Block>
        <About.Title>
          <p>Nice to meet you!</p>
        </About.Title>
        <About.Text>
          <p>My name is Kseniya and I am from Siberia. </p>
          <p>
            I love photo shots filled with light, gentle thin portraits, pure
            natural shades. I love your natural beauty and emotions, captured
            moments highlighted by light.
          </p>
          <p>
            I don’t edit or retouch photos, I don’t use Photoshop, I try to
            shoot right away so that the processing is kept to a minimum.
          </p>
          <p>I have been photographing for over 10 years.</p>
          <p>I was trained:</p>
          <ul>
            <li>
              workshop by photo-duet{' '}
              <About.Link href="https://blog.ps-photo.ru/" target="_blank">
                Post Scriptum
              </About.Link>
            </li>
            <li>
              workshops on film medium format photography by{' '}
              <About.Link href="https://lighthousefilmlab.com/" target="_blank">
                Lighthouse Film Lab
              </About.Link>{' '}
              and{' '}
              <About.Link href="https://carmencitafilmlab.com/" target="_blank">
                Сarmencita Film lab
              </About.Link>
            </li>
            <li>
              workshop by{' '}
              <About.Link href="http://maxkoliberdin.com/" target="_blank">
                Maxim Koliberdin
              </About.Link>
            </li>
            <li>
              workshop by{' '}
              <About.Link href="http://www.nastiavesna.com/" target="_blank">
                Nastia Vesna
              </About.Link>
            </li>
          </ul>
        </About.Text>
      </About.Block>
    </About>
  );
}
