import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    display: flex;
    justify-content: center;
    font-family: Comfortaa;
    font-weight: 400;
    font-style: normal;
    line-height: 1.8em;
    font-size: 14px;
    text-transform: none;
  }

  .arrowIcons {
    width: 12px;
    margin: 0 5px;
  }

  // @supports (-webkit-overflow-scrolling: touch) {
  //   /* CSS specific to iOS devices */
  //   input, textarea, button {
  //       transform: translateZ(0px) !important;
  //   }
  // }
`;
