/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Header } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.png';

export function HeaderContainer() {
  const [subMenuOn, setSubMenuOn] = useState(false);
  const [menuOn, setMenuOn] = useState(false);

  function subMenuClickHandle(isHeader = false) {
    const container = document.getElementById('headerContainer');
    const menuItem = document.getElementById('headerMenuItem');
    const subMenu = document.getElementById('headerSubMenu');

    const menu = document.getElementById('headerMenu');

    if (subMenuOn) {
      container.classList.remove('subMenuOn');
      menuItem.classList.remove('subMenuOn');
      subMenu.classList.remove('subMenuOn');

      if (!isHeader) {
        menu.classList.remove('menuOn');
        container.classList.remove('menuOn');
        setMenuOn(false);
      }

      setSubMenuOn(false);
    } else {
      container.classList.add('subMenuOn');
      menuItem.classList.add('subMenuOn');
      subMenu.classList.add('subMenuOn');

      setSubMenuOn(true);
      isHeader ? null : setMenuOn(true);
    }
  }

  function menuClickHandle() {
    const container = document.getElementById('headerContainer');
    const menu = document.getElementById('headerMenu');

    const menuItem = document.getElementById('headerMenuItem');
    const subMenu = document.getElementById('headerSubMenu');

    if (menuOn) {
      container.classList.remove('menuOn');
      menu.classList.remove('menuOn');

      container.classList.remove('subMenuOn');
      menuItem.classList.remove('subMenuOn');
      subMenu.classList.remove('subMenuOn');

      setMenuOn(false);
    } else {
      container.classList.add('menuOn');
      menu.classList.add('menuOn');

      setMenuOn(true);
    }

    setSubMenuOn(false);
  }

  return (
    <Header id="headerContainer">
      <Header.Logo to={ROUTES.HOME} alt="Logo" src={logo} />
      <Header.Menu id="headerMenu">
        <Header.MenuItem classNames="menuSolid" to={ROUTES.HOME}>
          Home
        </Header.MenuItem>
        <Header.SubMenu
          classNames="menuSolid"
          id="headerSubMenu"
          title="Portfolio"
          onClick={() => subMenuClickHandle(true)}
        >
          <div>
            <Header.SubMenuItem
              onClick={() => subMenuClickHandle()}
              to={ROUTES.WEDDING}
            >
              Wedding
            </Header.SubMenuItem>
            <Header.SubMenuItem
              onClick={() => subMenuClickHandle()}
              to={ROUTES.ENGAGEMENT}
            >
              Engagement
            </Header.SubMenuItem>
            <Header.SubMenuItem
              onClick={() => subMenuClickHandle()}
              to={ROUTES.FAMILYANDPREGNANCY}
            >
              Family&Pregnancy
            </Header.SubMenuItem>
            <Header.SubMenuItem
              onClick={() => subMenuClickHandle()}
              to={ROUTES.PORTRAIT}
            >
              Portrait
            </Header.SubMenuItem>
          </div>
        </Header.SubMenu>
        <Header.MenuItem to={ROUTES.ABOUT} id="headerMenuItem">
          About
        </Header.MenuItem>
        <Header.MenuItem to={ROUTES.CONTACT}>Contact</Header.MenuItem>{' '}
        <Header.MenuItem to={ROUTES.FORCLIENTS} id="headerMenuItem">
          For clients
        </Header.MenuItem>
        <Header.MenuMobileButton onClick={() => menuClickHandle()}>
          Menu
        </Header.MenuMobileButton>
      </Header.Menu>
    </Header>
  );
}
