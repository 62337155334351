/* eslint-disable prefer-object-spread */
import React, { useEffect, useState } from 'react';
import images from '../data/images.json';
import { Portfolio } from '../components';

export function PortfolioContainer({ page }) {
  const [currentImages, setCurrentImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState([]);

  useEffect(() => {
    setCurrentImages(images.find((item) => item.page === page).galleries);
    setImagesLoading(new Array(currentImages.length));
  }, [page]);

  function updateLoadingState(item, index) {
    const arr = [...imagesLoading];
    arr[index] = item;
    setImagesLoading(arr);
  }

  return (
    <Portfolio>
      {currentImages
        .slice(0)
        .reverse()
        .map((item, index) => (
          <Portfolio.Item
            key={`${page}-${item.id}`}
            className={!imagesLoading[index] ? 'hidden-item' : 'active-item'}
          >
            <Portfolio.StyledSkeleton
              className={
                !imagesLoading[index] ? 'active-skeleton' : 'hidden-skeleton'
              }
            />

            <Portfolio.Image
              onLoad={() => {
                updateLoadingState(true, index);
              }}
              className={!imagesLoading[index] ? 'hidden-img' : 'active-img'}
              src={`/images/portfolio/${page.toLowerCase()}/${item.id}/${
                item.mainPhotoFileName
              }`}
              to={`/portfolio/${page.toLowerCase()}/${item.id}`}
            />

            <Portfolio.Title>{item.title}</Portfolio.Title>
            {item.subTitle === '' ? null : (
              <Portfolio.SubTitle>{item.subTitle}</Portfolio.SubTitle>
            )}
          </Portfolio.Item>
        ))}
    </Portfolio>
  );
}
