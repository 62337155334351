import styled from 'styled-components/macro';

export const Form = styled.form`
  margin-top: 20px;
  max-width: 1100px;
  display: grid;
  grid-template-columns: repeat(1, 100%);
  margin: 30px 0;
  padding: 20px 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0;
`;

export const Block = styled.div`
  user-select: none;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const Label = styled.label``;

export const Input = styled.input`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 16px;
  width: 100%;
  min-height: 20px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;

  -webkit-appearance: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
`;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 16px;
  width: 100%;
  min-height: 100px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  resize: vertical;

  -webkit-appearance: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
`;

export const Button = styled.button`
  margin-top: 20px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 16px;
  width: 100%;
  min-height: 20px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;

  -webkit-appearance: none;
  -webkit-box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  color: rgba(0, 0, 0, 0.8);

  font-weight: 600;
  cursor: pointer;

  &.inactive {
    color: rgba(0, 0, 0, 0.2);
    cursor: default;
  }

  &.active {
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      transition: 0.5s;
    }

    &:active {
      background-color: white;
      transition: 0s;
    }
  }
`;
