import React from 'react';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { AboutContainer } from '../containers/about';

export default function Browse() {
  return (
    <>
      <HeaderContainer />
      <AboutContainer />
      <FooterContainer />
    </>
  );
}
