import React from 'react';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { GalleryContainer } from '../containers/gallery';

export default function Gallery({ page }) {
  return (
    <>
      <HeaderContainer />
      <GalleryContainer page={page} />
      <FooterContainer />
    </>
  );
}
