import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

export const Container = styled.div`
  margin-top: 20px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 5px;

  @media (max-width: 765px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 510px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  align-items: center;
  margin-top: 20px;
  box-sizing: border-box;

  display: block;

  &.hidden-item {
    display: block;
  }
  &.active-item {
    display: flex;
    flex-direction: column;
  }

  &:first-of-type,
  :nth-of-type(3n + 1) {
    margin-left: 0;
  }

  > a {
    height: 100%;
  }

  position: relative;
`;

export const ImageWrap = styled.div`
  height: 100%;
`;

export const Image = styled.img`
  user-select: none;
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;

  &:hover {
    opacity: 0.85;
    transition: 0.5s;
  }

  &.hidden-img {
    display: none;
  }

  &.active-img {
    display: block;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  width: 400px;
  height: 600px;

  &.hidden-skeleton {
    display: none;
  }

  &.active-skeleton {
    display: block;
    margin-top: 25px;
  }
`;

const Text = styled.p`
  font-family: Comfortaa;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  user-select: none;
`;

export const Title = styled(Text)`
  user-select: none;
  color: #363636;
  letter-spacing: 1px;
  text-transform: none;
  line-height: 1.2em;
  margin-bottom: 0;
`;

export const SubTitle = styled(Text)`
  user-select: none;
  margin-top: 5px;
  line-height: 1.4em;
  margin: 0 0 10 px 0;
  color: #5c5c5c;
`;
