/* eslint-disable no-shadow */
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

import {
  Container,
  Item,
  ImageWrap,
  Image,
  Title,
  SubTitle,
  StyledSkeleton,
} from './styles/portfolio';

export default function Portfolio({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Portfolio.Item = function PortfolioItem({ children, ...restProps }) {
  return <Item {...restProps}>{children}</Item>;
};

Portfolio.Image = function PortfolioImage({ to, children, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <ImageWrap>
        <Image {...restProps}>{children}</Image>
      </ImageWrap>{' '}
    </ReactRouterLink>
  );
};

Portfolio.Title = function PortfolioTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Portfolio.SubTitle = function PortfolioSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Portfolio.StyledSkeleton = function PortfolioStyledSkeleton({
  children,
  ...restProps
}) {
  return <StyledSkeleton {...restProps} />;
};
