import React from 'react';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { PortfolioContainer } from '../containers/portfolio';

export default function Portfolio({ page }) {
  return (
    <>
      <HeaderContainer />
      <PortfolioContainer page={page} />
      <FooterContainer />
    </>
  );
}
