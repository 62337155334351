import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin: 30px 0;
  box-sizing: border-box;
`;

export const Link = styled.a`
  user-select: none;
  margin-left: 15px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);

  &:after {
    content: '|';
    margin-left: 15px;
  }

  &:last-of-type:after {
    content: '';
    margin-left: 0;
  }

  &:hover {
    color: rgba(0, 0, 0, 1);
    transition: 0.5s;
  }

  &:first-of-type {
    margin-left: 0;
  }

  display: block;
  text-decoration: none;
  box-sizing: border-box;
`;
