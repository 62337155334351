import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 50px 50px 0;
  box-sizing: border-box;

  @media (max-width: 940px) {
    flex-direction: column;
  }

  @media (max-width: 1200px) {
    padding: 0 10px;
    padding-top: 25px;
    align-items: center;
  }
`;

export const Block = styled.div`
  width: 50%;
  max-width: 500px;
  box-sizing: border-box;

  @media (max-width: 940px) {
    width: 100%;

    &:first-of-type {
      order: 1;
    }
  }
`;

export const Image = styled.img`
  max-width: 90%;
  user-select: none;

  @media (max-width: 940px) {
    max-width: 100%;
  }
`;

export const Title = styled.div`
  user-select: none;
  color: #222;
  font-family: Comfortaa;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5em;
  font-size: 1.3em;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
  margin: 1em 0 0.3em;
`;

export const Text = styled.div`
  user-select: none;
  color: #5c5c5c;
  font-family: Comfortaa;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8em;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  margin: 0 0 1.8em;
`;

export const Link = styled.a`
  user-select: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);

  &:hover {
    color: rgba(0, 0, 0, 1);
    transition: 0.5s;
  }

  // display: block;
  text-decoration: none;
  box-sizing: border-box;
`;
