import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 25px 25px;
  box-sizing: border-box;
  object-fit: cover;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0;
    padding-top: 25px;
  }
`;

export const Slide = styled.img`
  user-select: none;
  max-width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;

  -webkit-transition: opacity 1s ease-out;

  &.active {
    opacity: 1;
    height: auto;
  }
`;
