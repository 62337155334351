import React from 'react';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { PortfolioNav } from '../components';
import images from '../data/images.json';

export function PortfolioNavContainer({ nextId, prevId, page }) {
  const currentPage = images.find((items) => items.page === page).galleries;
  const prevGallery = currentPage.find((item) => item.id === +prevId);
  const nextGallery = currentPage.find((item) => item.id === +nextId);

  function clickHandler() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <PortfolioNav>
      {prevId ? (
        <PortfolioNav.Prev
          onClick={() => clickHandler()}
          to={`/portfolio/${page.toLowerCase()}/${prevId}`}
        >
          <ArrowLeftIcon className="arrowIcons" />
          <span>{prevGallery.title}</span>
        </PortfolioNav.Prev>
      ) : (
        <div />
      )}

      <PortfolioNav.ToTop onClick={() => clickHandler()}>
        Top
      </PortfolioNav.ToTop>

      {nextId ? (
        <PortfolioNav.Next
          onClick={() => clickHandler()}
          to={`/portfolio/${page.toLowerCase()}/${nextId}`}
        >
          <span>{nextGallery.title}</span>
          <ArrowRightIcon className="arrowIcons" />
        </PortfolioNav.Next>
      ) : (
        <div />
      )}
    </PortfolioNav>
  );
}
