/* eslint-disable no-shadow */
import React from 'react';
import { Container, Block, Image, Title, Text, Link } from './styles/about';

export default function About({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

About.Block = function AboutBlock({ children, ...restProps }) {
  return <Block {...restProps}>{children}</Block>;
};

About.Image = function AboutImage({ children, ...restProps }) {
  return <Image {...restProps}>{children}</Image>;
};

About.Title = function AboutTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

About.Text = function AboutText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

About.Link = function AboutLink({ children, ...restProps }) {
  return <Link {...restProps}>{children}</Link>;
};
