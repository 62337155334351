import React from 'react';
import { useParams } from 'react-router-dom';
import images from '../data/images.json';
import { Gallery } from '../components';
import { PortfolioNavContainer } from './portfolionav';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

export function GalleryContainer({ page }) {
  const { id } = useParams();
  const currentPage = images.find((items) => items.page === page).galleries;
  const currentGallery = currentPage.find((item) => item.id === +id);

  const nextId =
    currentPage.indexOf(currentGallery) > 0
      ? currentPage[currentPage.indexOf(currentGallery) - 1].id
      : null;
  const prevId =
    currentPage.indexOf(currentGallery) < currentPage.length - 1
      ? currentPage[currentPage.indexOf(currentGallery) + 1].id
      : null;

  const { imageFileNameStart } = currentGallery;
  const { imageFileNameEnd } = currentGallery;
  const indexes = Array.from(
    { length: imageFileNameEnd - imageFileNameStart + 1 },
    (_, i) => i + imageFileNameStart
  );

  function goUp() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  goUp();

  return (
    <>
      <Gallery>
        <Gallery.Title>{currentGallery.title}</Gallery.Title>
        {currentGallery.subTitle === '' ? null : (
          <Gallery.SubTitle>{currentGallery.subTitle}</Gallery.SubTitle>
        )}

        <Gallery.Items>
          {indexes.map((item, index) => (
            <Gallery.Item
              key={`${currentGallery.title}-${currentGallery.subTitle}-${index}`}
              data-src={`/images/portfolio/${page.toLowerCase()}/${id}/image${item}.jpg`}
              className="lazyload"
            />
          ))}
        </Gallery.Items>
      </Gallery>

      <PortfolioNavContainer nextId={nextId} prevId={prevId} page={page} />
    </>
  );
}
