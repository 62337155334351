import React from 'react';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { ForClientsContainer } from '../containers/forclients';

export default function Browse() {
  return (
    <>
      <HeaderContainer />
      <ForClientsContainer />
      <FooterContainer />
    </>
  );
}
