import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';

export const MenuMobileButton = styled.div`
  user-select: none;
  display: none;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-width: 1px 0;
  width: 100%;
  margin-top: 10px;
  padding: 10px 0;
  cursor: pointer;

  @media (max-width: 640px) {
    display: block;
  }

  &:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

export const MenuItem = styled(ReactRouterLink)`
  user-select: none;
  white-space: nowrap;
  margin-left: 30px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  transition: opacity 0.5s ease-out;

  &:hover {
    color: rgba(0, 0, 0, 1);
    transition: 0.5s;
  }

  &.active {
    color: rgba(0, 0, 0, 1);
  }

  &:first-of-type {
    margin-left: 0;
  }

  @media (max-width: 640px) {
    margin: 0;
    transition: 0.5s ease-out; 

    &.subMenuOn {
      margin-top: 100px;
      transition: 0.5s ease-out;
    }

    &.menuSolid {
      display: block;
      width: 100%;
      background-color: white;
    }
  }
}
`;

export const Container = styled.div`
  user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  padding: 50px 50px 0;
  box-sizing: border-box;

  @media (max-width: 510px) {
    padding: 0 10px;
    padding-top: 10px;
  }

  @media (max-width: 640px) {
    margin-top: 50px;
    transition: 0.5s ease-out;

    &.menuOn {
      margin-top: 206px;

      ${MenuMobileButton} {
        color: rgba(0, 0, 0, 1);
      }
    }

    &.subMenuOn {
      margin-top: 305px;
      transition: 0.5s ease-out;
    }
  }
`;

export const Logo = styled.img`
  line-height: 0;
  margin: 0;
  display: block;
  max-height: 130px;
  height: auto;
  width: auto;
  max-width: 100%;
`;

export const Menu = styled.div`
  line-height: 1.6em;
  width: auto;
  margin: auto;
  text-decoration: none;
  font-family: brigade;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.6em
  width: auto;
  margin: auto;
  list-style: none;
  list-style-image: none;
  margin-top: 50px;
  z-index: 10;

  @media (max-width: 640px) {
    position: absolute;
    margin: 0;
    background-color: white;
    top: -155px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 18px;
    transition: 0.5s ease-out;

    &.menuOn {
      top: 0;
      transition: 0.5s ease-out;
    }
  }
`;

export const SubMenuItem = styled(MenuItem)`
  user-select: none;
  margin-left: 0;
  margin-top: 5px;
  text-align: left;

  &.active {
    color: rgba(0, 0, 0, 1);
  }

  @media (max-width: 640px) {
    font-weight: 200;
    font-size: 14px;
    line-height: 1.4em;
    text-align: center;
  }
`;

export const SubMenu = styled.div`
  position: relative;
  margin-left: 30px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 30px;

  &:hover {
    color: rgba(0, 0, 0, 1);
    transition: 0.5s;
  }

  @media (min-width: 641px) {
    &:hover {
      & > div {
        display: block;
      }
    }
  }

  &.menuSolid {
    display: block;
    width: 100%;
    background-color: white;
  }

  & > div {
    display: none;
    border-radius: 3px;
    top: 20px;
    left: -10px;
    padding: 5px 10px;
    box-sizing: border-box;
    background-color: white;
    height: auto;
    position: absolute;
  }

  &.active {
    color: rgba(0, 0, 0, 1);
  }

  @media (max-width: 640px) {
    margin: 0;
    padding: 0;
    width: 100%;
    possition: relative;
    display: block;

    & > div {
      transition: 0.5s ease-out;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.05);
      padding: 5px;
      margin: 0;
      top: -80px;
      left: 0;
      display: block;
      z-index: -10;
    }

    &.subMenuOn > div {
      top: 25px;
      transition: top 0.5s ease-out;
    }
  }
`;
