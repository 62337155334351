import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
`;

export const Title = styled.p`
  user-select: none;
  width: 100%;
  text-align: center;
  margin-bottom: 0;
  font-family: Comfortaa;
  font-weight: 300;
  font-style: normal;
  line-height: 1.2em;
  font-size: 20px;
  text-transform: none;
  text-decoration: none;
  letter-spacing: 1px;
  color: #222;
`;

export const SubTitle = styled.p`
  user-select: none;
  text-align: left;
  margin-bottom: 0;
  color: #444;
  font-family: Comfortaa;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8em;
  font-size: 14px;
  text-transform: none;
  text-decoration: none;
  margin: 0 0 1.8 em;
`;

export const Description = styled.p`
  user-select: none;
  font-family: Comfortaa;
  font-weight: 400;
  line-height: 1.8em;
  font-size: 14px;
  text-transform: none;
`;

export const Items = styled(Container)``;

export const Item = styled.img`
  user-select: none;
  max-width: 500px;
  margin-top: 5px;

  @media (max-width: 510px) {
    max-width: 100%;
  }
`;

export const Nav = styled.div``;
