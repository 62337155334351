/* eslint-disable no-shadow */
import React from 'react';
import {
  Container,
  Title,
  SubTitle,
  Description,
  Items,
  Item,
  Nav,
} from './styles/gallery';

export default function Gallery({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Gallery.Title = function GalleryTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Gallery.SubTitle = function GallerySubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Gallery.Description = function GalleryDescription({ children, ...restProps }) {
  return <Description {...restProps}>{children}</Description>;
};

Gallery.Items = function GalleryItems({ children, ...restProps }) {
  return <Items {...restProps}>{children}</Items>;
};

Gallery.Item = function GalleryItem({ children, ...restProps }) {
  return <Item {...restProps}>{children}</Item>;
};

Gallery.Nav = function GalleryNav({ children, ...restProps }) {
  return <Nav {...restProps}>{children}</Nav>;
};
