import React from 'react';
import { HeaderContainer } from '../containers/header';
import { SlidesContainer } from '../containers/slides';
import { FooterContainer } from '../containers/footer';

export default function Browse() {
  return (
    <>
      <HeaderContainer />
      <SlidesContainer />
      <FooterContainer />
    </>
  );
}
