/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { About, ContactForm } from '../components';

export function ContactContainer() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  function encode(data) {
    return Object.keys(data)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
      )
      .join('&');
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (name && email && message) {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', name, email, message }),
      })
        .then(() => {
          alert('Your message was sent! Thank you!');
          setName('');
          setEmail('');
          setMessage('');
        })
        .catch((error) => alert(error));
    }
    // else {
    //   alert('Please, fill in empty fields!');
    // }
  }

  return (
    <About>
      <About.Block>
        <About.Image src="/images/contact/contact.jpeg" />
      </About.Block>
      <About.Block>
        <About.Title>
          <p>Thank you for your interest!</p>
        </About.Title>
        <About.Text>
          <p>
            To know any details of photo shootings or the costs please use
            contact options convenient for you. You can find my contacts at the
            very bottom of the page.
          </p>{' '}
          <p>I will answer any of your questions.</p>
          <p>
            You can also use the request form below. In your message, please
            specify: what kind of photo shooting would you like (Wedding /
            Engagement / Family&Pregnancy / Portrait), preferable date of photo
            shooting.
          </p>
        </About.Text>

        <ContactForm netlify name="contact" onSubmit={handleSubmit}>
          <ContactForm.Block>
            <ContactForm.Label htmlFor="name">Name</ContactForm.Label>
            <ContactForm.Input
              type="text"
              id="name"
              name="name"
              placeHolder="Your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </ContactForm.Block>
          <ContactForm.Block>
            <ContactForm.Label htmlFor="email">Email</ContactForm.Label>
            <ContactForm.Input
              type="email"
              id="email"
              name="email"
              placeHolder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </ContactForm.Block>
          <ContactForm.Block>
            <ContactForm.Label htmlFor="message">Message</ContactForm.Label>
            <ContactForm.TextArea
              id="message"
              name="message"
              value={message}
              placeHolder="Your message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </ContactForm.Block>
          <ContactForm.Button
            type="submit"
            className={name && email && message ? 'active' : 'inactive'}
          >
            Send
          </ContactForm.Button>
        </ContactForm>
      </About.Block>
    </About>
  );
}
