import React, { useState, useEffect } from 'react';
import { Slides } from '../components';
import images from '../data/images.json';

export function SlidesContainer() {
  const { imageFileNameStart } = images.find((item) => item.page === 'Home');
  const { imageFileNameEnd } = images.find((item) => item.page === 'Home');
  const [activeImageIndex, setActiveImageIndex] = useState(1);

  const indexes = Array.from(
    { length: imageFileNameEnd - imageFileNameStart + 1 },
    (_, i) => i + imageFileNameStart
  );

  function randomIndex() {
    let index = 1;
    while (index === activeImageIndex) {
      index = Math.floor(Math.random() * imageFileNameEnd + 1);
    }

    setActiveImageIndex(index);
  }

  useEffect(() => {
    const timer = setInterval(() => randomIndex(), 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <Slides>
      {indexes.map((image, index) => (
        <Slides.Slide
          key={`image-${index}`}
          src={`/images/home/image${image}.jpg`}
          className={image === activeImageIndex ? 'active' : null}
        />
      ))}
    </Slides>
  );
}
